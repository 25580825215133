export const fetchApi = async ({
  subUrl = '',
  method = 'GET',
  lang,
  reqBody = null,
  header = {},
  baseUrl,
  signal = null,
}) => {
  try {
    if (baseUrl) {
      const userAgent = navigator.userAgent;
      const res = await fetch(`${baseUrl}${subUrl}`, {
        method,
        signal,
        headers: {
          'Content-Type': 'application/json',
          locale: lang,
          authorization: window.localStorage.getItem('authToken') ?? '',
          Cookie: `i18next=${lang}`,
          device: userAgent,
          ...header,
        },
        body: reqBody ? JSON.stringify(reqBody) : null,
      })
      return await res.json()
    }
  } catch (error) {
    return error?.message
  }
}
