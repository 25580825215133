import { getUserInfoService } from '@/services/providerServices'
import useTranslation from 'next-translate/useTranslation'
import { createContext, useContext, useState } from 'react'

export const AppContext = createContext()
export const useAppContext = () => useContext(AppContext)

const AppProvider = ({ children }) => {
  const { lang } = useTranslation('translations-auction')
  const [userDetails, setUserDetails] = useState(null)

  const getUserInfo = async () => {
    await getUserInfoService(false, lang, setUserDetails)
  }

  return (
    <AppContext.Provider
      value={{
        userDetails,
        getUserInfo,
        setUserDetails,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider
