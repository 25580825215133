module.exports={
  "locales": ["en", "ar"],
  "defaultLocale": "ar",
  "localeDetection": false,
  "loadLocaleFrom":  (lang) =>
  {
    return fetch(lang === 'en' ? `${process.env.NEXT_PUBLIC_CMS_STRAPI_STATIC_PAGE}translations?slug=translations-auction&locale=en` : `${process.env.NEXT_PUBLIC_CMS_STRAPI_STATIC_PAGE}translations?slug=translations-auction&locale=ar-SA`).then((res) => res.json()) // Use .json() to parse the response
    .then((data) => data.translation_data); // getting translation_data key
  },
 "pages": {
   "*": ["translations-auction"]
 }
}
