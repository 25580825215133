export const getUserInfoService = async (
  isDashboard = false,
  lang,
  setUserDetails = () => {},
  getSavedItemsCount = () => {},
  setLoadUser = () => {},
  setIsWalletCreated = () => {},
  checkWalletCreated = () => {},
  getRequirementsCount = () => {},
) => {
  if (!localStorage.getItem('authToken')) {
    if (isDashboard) {
      getSavedItemsCount()
      setLoadUser(false)
      setIsWalletCreated(false)
    }
    setUserDetails(null)
    return
  }
  try {
    if (isDashboard) {
      checkWalletCreated()
      getRequirementsCount()
    }
    const res = await fetch(`${process.env.NEXT_PUBLIC_GATEWAY_URL}${'user/profile'}`, {
      method: 'GET',
      headers: {
        locale: lang,
        Cookie: `i18next=${lang}`,
        authorization: localStorage.getItem('authToken'),
      },
    })
    const response = await res.json()
    let firstName = response?.data?.fullName
    if (firstName) {
      localStorage.setItem('info', JSON.stringify({ firstName: response?.data?.fullName }))
    }
    isDashboard && setLoadUser(false)
    if (!response?.data) {
      localStorage.removeItem('authToken')
      return
    }
    setUserDetails(response.data)
    if (response?.data?.phoneNumber) {
      localStorage.setItem('phoneNumber', response?.data?.phoneNumber)
    }
  } catch (error) {
    console.log('error occured', error)
    isDashboard && setLoadUser(false)
  }
}
