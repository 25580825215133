
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import React, { useEffect, useState } from 'react'
import i18nConfig from '../../i18n'
import appWithI18n from 'next-translate/appWithI18n'
import useTranslation from 'next-translate/useTranslation'
import UCookies from 'universal-cookie'
import Axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '@/styles/global.css'
import '@/styles/swiper-custom.scss'
import '@/styles/base.scss'
import '@/styles/mapBox.scss'
import { initializeProjectStore, initializeStore } from '@/utils/propertyStore'
import Head from 'next/head'
import mixpanel from 'mixpanel-browser'
import { authCheck } from '@/utils/authCheck'
import AppProvider from '@/lib/AppProvider'

Axios.defaults.baseURL = process.env.NEXT_PUBLIC_SERVER_BASE_URL + '/api'
Axios.defaults.withCredentials = true
const MyApp = ({ Component, pageProps }) => {
  const { lang } = useTranslation('translations-auction')

  mixpanel.init(process.env.NEXT_PUBLIC_AUCTION_MIXPANEL)
  toast.configure({ limit: 3, rtl: lang == 'ar' ? true : false })
  initializeStore()
  initializeProjectStore()

  //call refresh token api when page loads
  if (typeof window !== 'undefined') {
    authCheck(lang)
  }

  /**
   * Handle app lang
   * @return void
   */
  useEffect(() => {
    //dependency on refresh-token api
    window.lang = lang
    ;(async () => {
      const direction = lang === 'en' ? 'ltr' : 'rtl'
      setDirection(direction, lang)
    })()
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [lang])

  useEffect(() => {
    const cookie = new UCookies()
    if (cookie.get('authToken')) {
      localStorage.setItem('userId', cookie.get('userId'))
      localStorage.setItem('authToken', cookie.get('authToken'))
      localStorage.setItem('refreshToken', cookie.get('refreshToken'))
      localStorage.setItem('authStoredDate', cookie.get('authStoredDate'))
      localStorage.setItem('encryptedPhoneNumber', cookie.get('encryptedPhoneNumber'))
    } else {
      localStorage.removeItem('userId')
      localStorage.removeItem('authToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('authStoredDate')
      localStorage.removeItem('encryptedPhoneNumber')
    }

    const checkForAuthToken = () => {
      authCheck(lang)
    }

    document.addEventListener('click', checkForAuthToken)

    return () => {
      document.removeEventListener('click', checkForAuthToken)
    }
  }, [])

  /**
   * Set app dircetion and language
   * @return void
   */
  const setDirection = (direction, language) => {
    document.documentElement.dir = direction
    document.documentElement.lang = language
  }
  return (
    <>
      <Head>
        <meta name='google' content='notranslate' />
        {process.env.NEXT_PUBLIC_ADD_NO_INDEX == 'true' && <meta name='robots' content='noindex'></meta>}
        <link
          href={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/fonts/iconography.css`}
          rel='stylesheet'
          as='style'
          fetchPriority='high'
        />
      </Head>
        <AppProvider>
          <Component {...pageProps} />
        </AppProvider>
    </>
  )
}

const __Page_Next_Translate__ = appWithI18n(MyApp, i18nConfig)


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      
    });
  